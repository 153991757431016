import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/src/css/core/index.scss";

// icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faClose,
  faUser,
  faHeart,
  faThumbsUp,
  faThumbsDown,
  faShare,
  faAngleDown,
  faAngleUp,
  faBackward,
  faPlayCircle,
  faPauseCircle,
  faForward,
  faKey,
  faArrowRightFromBracket,
  faSearch,
  faEye,
  faTimes,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon  } from "@fortawesome/vue-fontawesome";

// sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// element plus
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import ptBR from "element-plus/es/locale/lang/pt-br";

// animations
import "animate.css";

import "vue3-carousel/dist/carousel.css";
import "./assets/scss/root.scss";

// fonts
import "@fontsource/raleway";
import "@fontsource/roboto";
import "@fontsource/poppins";

library.add(
  faClose,
  faUser,
  faHeart,
  faThumbsUp,
  faThumbsDown,
  faShare,
  faAngleDown,
  faAngleUp,
  faBackward,
  faPlayCircle,
  faPauseCircle,
  faForward,
  faKey,
  faArrowRightFromBracket,
  faSearch,
  faEye,
  faTimes,
  faPen);

const app = createApp(App);

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

Sentry.init({
  app,
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["biblioteca.orienteme.com", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueSplide);
app.use(router);
app.use(store);
app.use(ElementPlus, {
  locale: ptBR,
});
app.mount("#app");
