import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import useStore from '@/store';
import _ from 'lodash';
import { closeLoading, showLoadingMessage } from '../helper/useLoading';
import { ElNotification } from 'element-plus';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    name: 'base',
  },
  {
    path: '/home',
    component: () => import('../views/Home.vue'),
    name: 'Home'
  },

  {
    path: '/detail/:id',
    component: () => import('../views/details.vue'),
    name: 'detailAudio'
  },
  {
    path: '/autor/:id',
    component: () => import('../views/autor.vue'),
    name: 'autor',

  },
  {
    path: '/frame/:id',
    component: () => import('../views/frame.vue'),
    name: 'Frame'
  },

  {
    path: '/detailVideo/:id',
    component: () => import('../views/detailVideo.vue'),
    name: 'detailVideo'
  },


  {
    path: '/login',
    component: () => import('../views/loginPage.vue'),
    name: 'login',
  },
  {
    path: '/resetpassword/:token',
    component: () => import('../views/resetpassword.vue'),
    name: 'resetpassword'
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/home',
    name: 'redirect'
  }

]
const router = createRouter({
  history: createWebHistory(),
  routes
});

async function externalLoginWithToken(to: any) {
  const store = useStore;
  // parse all Token to lowercase
  const token = to.query.token || to.query.Token;
  // set token in store
  const errorList = await store.dispatch('LOGIN_BY_URL', token);
  if (errorList && store.state.isLoged) {
    router.replace('/home');
    return;
  }
  router.replace('/login');
}


router.beforeEach(async (to) => {
  showLoadingMessage('Carregando página');
  const store = useStore;
  // if to has a Token Parameter
  if (to.query.token || to.query.Token) {
    await externalLoginWithToken(to);
  }
  else if (
    // make sure the user is authenticated
    _.isEmpty(store.getters.getToken) &&
    // Avoid an infinite redirect
    (to.name !== 'login' && to.name !== 'resetpassword')
    // if login contens query parameter Token
  ) {
    // redirect the user to the login page
    closeLoading();
    return { name: 'login' }
  }
  closeLoading();
})
router.afterEach((to) => {
  // check is just a query parameters added to the url, ignore scroll top
  if(to.query.page == null){
    window.scrollTo(0, 0);
  }
})
export default router