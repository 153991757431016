import { useLocalStorage } from '@vueuse/core';

import State from "../../types/state"
import useIndexedDB from '@/services/useIndexDb';

const token  = JSON.parse(localStorage.getItem('token') || '{}')

const loged = !token.authenticated  ? false : true

export const state:State = {

    isLoginOpen:false,
    isRegisterOpen: false,
    isLoged: loged,
    isPlaying: false,
    isPlayerShow: false,
    isEditing: false,
    postList: [],
    postToContinue: [],
    post: {},
    favoritePostList: [],
    highlightsList: [],
    user: useLocalStorage('user', {} as any),
    author: {},
    indicatedpostlist:{},
    postListBlog:{},
    category: 1,
    indicatedpostlistSingle:{},
    token: token,
    tokenPersist: useLocalStorage('tokenPersist', {} as any),
    cachePosts: useIndexedDB('cachePosts', 'posts', 'id', 1),
    cacheImagens: useIndexedDB('cacheImagens', 'images', 'uuid', 1),
    cacheAuthors: useLocalStorage('cacheAuthors', [] as any),
    listQuery: {},
    listByCategory: {},
    selectedCategory: 0,
    userProfilePicture: null,
    lastUserLogon: useLocalStorage('lastUserLogon', ''),
    login: useLocalStorage('loginState', [] as any),
    searchFind: {
        name: '',
        state: false
    }
    
}
