import {createStore} from "vuex"
import {state, mutations, actions, getters, loginActions} from "./rootStore"

const token = JSON.parse(localStorage.getItem('token') || '{}')

const now = new Date() as any;    

if(token.token){
    setTimeout(() => {  

        localStorage.removeItem('token')

    }, new Date(token.tokenExpiration) as any - now)
}

export default createStore({
    state,
    mutations,
    actions: {
        ...actions, ...loginActions
    },
    getters
})