import api from "@/services"
import type { TypeLoginResponse, TypeRefreshLoginRequest } from "@/types/loginData"
import { useLocalStorage } from "@vueuse/core"
import _ from "lodash"

export const loginActions = {

  LOGIN({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        api.post('login', payload).then(response => {
          if (response.data.data.authenticated) {
            dispatch('CLEAR_CACHE', payload.email);
            try{
              localStorage.setItem('token', JSON.stringify(response.data.data))
            }
            catch(error){
              localStorage.clear();
            }
            finally{
              localStorage.setItem('token', JSON.stringify(response.data.data))
            }
            const newToken = JSON.parse(localStorage.getItem('token') || '{}')
            if (payload.remindme) {
              commit('LOGIN_PERSIST', newToken)
            } else {
              commit('LOGIN', response.data.data)
              commit('SET_TOKEN', newToken)
            }
            
            commit('LOGIN_STATE', response.data.data)
            // register token refresh
            dispatch('REGISTER_TOKEN_REFRESH');
            
          }
          resolve(response.data.data.authenticated)
          if (response.data.data.authenticated) {
            dispatch('GET_POST_CONTINUE')
            dispatch('GET_POST_LIST_HIGHLIGHTS')
            dispatch('GET_INDICATED_POSTLIST')
            dispatch('GET_POST_LIST_BLOG')
            dispatch('GET_INDICATED_POST')
            dispatch('GET_USER')
          }
        }).catch(error => {
          console.log(error);
          resolve(error.response.data)
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  REGISTER_TOKEN_REFRESH({ state, dispatch }) {
    if (!_.isEmpty(state.tokenPersist)) {
      console.log('Keep the session alive');
      const payload = state.tokenPersist;
      const now = new Date() as any;
      setTimeout(() => {
        console.info('update refresh token:' + payload.tokenExpiration);
        dispatch('SEND_LOGIN_REFRESH', {
          uuid: payload.uuid,
          refreshToken: payload.refreshToken
        })
      }, new Date(payload.tokenExpiration) as any - now)
    }
  },
  SEND_LOGIN_REFRESH({ commit, dispatch }, playload: TypeRefreshLoginRequest) {
    return new Promise((resolve, reject) => {
      try {
        api.post('refreshtoken', playload).then(response => {
          if (response.data.data.authenticated) {
            localStorage.setItem('token', JSON.stringify(response.data.data))
            const newToken = JSON.parse(localStorage.getItem('token') || '{}')
            commit('LOGIN_PERSIST', newToken)

            // register token refresh
            dispatch('REGISTER_TOKEN_REFRESH', response.data.data);
          }
          resolve(response.data.data.authenticated)
          if (response.data.data.authenticated) {
            dispatch('GET_POST_CONTINUE')
            dispatch('GET_POST_LIST_HIGHLIGHTS')
            dispatch('GET_INDICATED_POSTLIST')
            dispatch('GET_POST_LIST_BLOG')
            dispatch('GET_INDICATED_POST')
          }
        }).catch(error => {
          resolve(error.response.data)
        })
      } catch (e) {
        reject(e)
      }
    })
  },

  SEND_FORGOT_PASSWORD({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const apikey = process.env.VUE_APP_API_KEY ?? '';
        api.post('forgotpassword', payload, {
          headers: {
            apikey
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error.response.data))
      } catch (e) {
        reject(e)
      }
    })
  },
  RESET_PASSWORD({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const apikey = process.env.VUE_APP_API_KEY ?? '';
        api.post('resetpassword', payload, {
          headers: {
            apikey
          }
        })
          .then(response => resolve(response))
          .catch(error => {
            resolve(error.response.data)
          })
      } catch (e) {
        reject(e)
      }
    })
  },
  LOGOUT_USER({ state }) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    state.token = {}
    state.tokenPersist = {};
    state.user = {
      picutre: null
    };
    return true;
  },
  CLEAR_CACHE({ state }, payload) {
    const email = payload;
    if (state.lastUserLogon != '') {
      if (state.lastUserLogon != email) {
        localStorage.removeItem('cachePosts');
        localStorage.removeItem('cacheImages');
      }
    }
    state.lastUserLogon = email;
  },
  LOGIN_BY_URL({dispatch, commit}, token){
    return new Promise((resolve, reject) => {
      try {
        dispatch('CLEAR_CACHE');
        api.post(`login/${token}`).then(response => {
          if (response.data.data.authenticated) {
            // clear cache and last user relate data
            dispatch('LOGOUT_USER');

            // set new user access
            localStorage.setItem('token', JSON.stringify(response.data.data))
            const newToken = JSON.parse(localStorage.getItem('token') || '{}')
            commit('LOGIN', response.data.data)
            commit('SET_TOKEN', newToken)
            
            commit('LOGIN_STATE', response.data.data)
            // register token refresh
            dispatch('REGISTER_TOKEN_REFRESH');
            
          }
          resolve(response.data.data.authenticated)
          if (response.data.data.authenticated) {
            dispatch('GET_POST_CONTINUE')
            dispatch('GET_POST_LIST_HIGHLIGHTS')
            dispatch('GET_INDICATED_POSTLIST')
            dispatch('GET_POST_LIST_BLOG')
            dispatch('GET_INDICATED_POST')
            dispatch('GET_USER')
          }
        }).catch(error => {
          resolve(error.response.data)
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  MOCK() {
    return new Promise((resolve, reject) => {
      try {
        fetch('https://run.mocky.io/v3/c6092f1b-54cd-47bd-b70d-c8a283eb8b67')
          .then(response => resolve(response.json()))
          .then(data => {
            reject(data)
          })
      } catch (e) {
        reject(e)
      }
    })
  },
}