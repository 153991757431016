import { ElNotification } from 'element-plus';
import { isGuidZero } from "@/helper/guid"
import api from "@/services"
import _ from "lodash"
const actions = {
  CLOSE_REGISTER({ commit }: any, payload: any): void {
    commit('CLOSE_REGISTER', payload)
  },
  OPEN_REGISTER({ commit }: any, payload: any): void {
    commit('OPEN_REGISTER', payload)
  },
  PLAY_PAUSE({ commit }: any, payload: any): void {
    commit('PLAY_PAUSE', payload)
  },
  PLAYER_SHOW_HIDE({ commit }: any, payload: any): void {
    commit('PLAYER_SHOW_HIDE', payload)
  },
  EDITING({ commit }: any, payload: any): void {
    commit('EDITING', payload);
  },
  async GET_POST({ commit, getters, state, dispatch }: any, payload: string | number): Promise<any> {
    if (!payload) return;
    if (state.cachePosts == null)
      state.cachePosts = [];
    if(typeof(payload) === 'string'){
      payload = parseInt(payload);
    }
    const postInCache = state.cachePosts.find((post: any) => post.id === payload);
    if (postInCache && postInCache.expirationDate != null && new Date(postInCache.expirationDate) > new Date()) {
      postInCache.typeDescription = await dispatch('GET_POST_TYPE_DESCRIPTION', postInCache);
      return Promise.resolve(postInCache);
    }
    return new Promise((resolve, reject) => {
      const { getToken } = getters
      api.get('post/' + payload, {
        headers: {
          'Authorization': 'Bearer ' + getToken.token
        }
      }).then(async (response) => {
        const post = response.data.data;
        post.typeDescription = await dispatch('GET_POST_TYPE_DESCRIPTION', post);
        commit('SET_POST', post)
        resolve(post)
      }).catch(error => {
        reject(error);
      })
    })
  },
  async GET_CLEAR_POST({ commit, getters }: any, payload: number): Promise<any> {
    if (!payload) return;
    return new Promise((resolve, reject) => {
      const { getToken } = getters
      api.get('post/' + payload, {
        headers: {
          'Authorization': 'Bearer ' + getToken.token
        }
      }).then(response => {
        commit('SET_POST', {...response.data.data, force: true})
        resolve(response.data.data)
      }).catch(error => {
        reject(error)
      });
    })
  },
  async GET_POST_LIST_HIGHLIGHTS({ commit, getters}: any): Promise<void> {
    const { getToken } = getters
    const response = await api.get('featuredpostlist', {
      headers: {
        'Authorization': 'Bearer ' + getToken.token,
        'Cache-Control': 'max=age=3600'

      }
    })
    const list: string[] = []
    const listItem = response.data.data.featuredPost
    if (listItem.length > 5) {
      let i = 0
      listItem.forEach(element => {
        if (i < 5) {
          list.push(element)
        }
        i++
      });
      commit('SET_POST_LIST_HIGHLIGHTS', list)
    } else {
      commit('SET_POST_LIST_HIGHLIGHTS', listItem)
    }
  },
  async GET_POST_LIST_BLOG({ commit, getters }): Promise<void> {
    const { getToken } = getters;
    const POST_TYPE = 1, PAGE = 1, PAGE_SIZE = 6, IS_RANDOM = true;
    const response = await api.get(`postlistbyposttype/${POST_TYPE}/${PAGE}/${PAGE_SIZE}/${IS_RANDOM}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    commit('SET_LIST_HOME_BLOG', response.data.data.posts)
  },
  async GET_POST_CONTINUE({ commit, getters }: any):Promise<void> {
    const { getToken } = getters
    try {
      const list: string[] = []
      const response = await api.get('posttocontinue', {
        headers: {
          'Authorization': 'Bearer ' + getToken.token
        }
      })
      const result = response.data.data.postToContinueList
      let i = 1
      result.forEach(element => {
        if (i <= 4) {
          list.push(element)
        }
        i++
      });
      commit('SET_POST_CONTINUE', list)
    } catch (e) {
      console.log(e)
    }
  },
  async SEARCH_POST_LIST_BY_TOPIC({ commit, getters }: any, payload: string): Promise<void> {
    const { getToken } = getters
    const response = await api.get(`postlistbytopicscovered/${payload}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    const responseTitle = await api.get(`postlistbytitle/${payload}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    let list = response.data.data?.length > 0 ? response.data.data : []
    if (!_.isEmpty(list)) {
      list = _.flatMap(list.map((item: any) => item.posts));
    }
    const listTitle = responseTitle.data.data?.posts.length > 0 ? responseTitle.data.data.posts : []
    if (list.length == 0) {
      commit('SET_SEARCHFIND', {
        state: true,
        name: payload
      })
      commit('SET_LIST_QUERY', [])
    }
    const allPosts = _.unionBy([...list, ...listTitle], 'postId');
    const posts: string[] = []
    if (allPosts.length > 0) {
      let i = 0
      allPosts.forEach((element: any) => {
        if (i < 8) {
          posts.push(element)
        }
        i++
      });
      commit('SET_LIST_QUERY', posts)
    } else {
      commit('SET_LIST_QUERY', [])
    }
  },
  async GET_FAVORISTE_LIST({ commit, getters }: any): Promise<void> {
    const { getToken } = getters
    const response = await api.get('favoritepostlist', {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    commit('SET_FAVORISTE_LIST', response.data.data.favoritePost)
  },
  async GET_AUTHOR({ commit, getters, state }: any, payload: number): Promise<any> {
    const { getToken } = getters
    if (state.cacheAuthors == null)
      state.cacheAuthors = [];
    const authorInCache = state.cacheAuthors.find((author: any) => author.id === payload);
    if (authorInCache) {
      console.debug('Retrieving author from cache');
      commit('SET_AUTHOR', authorInCache);
      return authorInCache;
    }

    const response = await api.get('author/' + payload, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    commit('SET_AUTHOR', response.data.data);
    return response.data.data
  },
  async GET_USER({ commit, getters, state, dispatch }: any) :Promise<void> {
    const { getToken } = getters
    let response = {} as any;
    const config = {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    };
    try {
      response = await api.get('user', config);
      if (response.data)
        commit('SET_USER', response.data.data)
    } catch (exc: any) {
      await dispatch('LOGOUT_USER', state);
    }

    // get user image
    try {
      const baseUrl = process.env.VUE_APP_URL_API_BASE;
      if(isGuidZero(response.data.data.pictureUuid)) {
        commit('SET_USER_PROFILE_PIC', null);
        return;
      }
      const imageResponse = await fetch(`${baseUrl}General/downloadfile/${response.data.data.pictureUuid}?h=80&w=80`, config)
      const blob = await imageResponse.blob();
      if (blob)
        commit('SET_USER_PROFILE_PIC', blob)
    } catch (exc: any) {
      return;
    }
  },
  async DOWNLOAD_FILE({ getters }, uuid) {
    // check uuid is all zeros
    if (uuid === '00000000-0000-0000-0000-000000000000')
      throw new Error('Invalid uuid');
    const baseUrl = process.env.VUE_APP_URL_API_BASE;
    const { getToken } = getters
    const config = {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    };
    const imageResponse = await fetch(`${baseUrl}General/downloadfile/${uuid}`, config)
    // convert blob to audio/mp3 format
    const blob = await imageResponse.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
  },
  async GET_INDICATED_POST({ commit, getters, dispatch }) {
    const { getToken } = getters
    const response = await api.get('postlistbyposttype/4/1/9', {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    const post = response?.data?.data?.posts[0];
    if (!post) return;
    dispatch('GET_HIGHLIGHT_VIDEO');
  },
  async GET_HIGHLIGHT_VIDEO({ commit, getters, dispatch }) {
    const { getToken } = getters
    const response = await api.get('postlistbyposttype/10/1/1', {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    });
    try{

      const postId = response?.data?.data?.posts[0].postId;
      const highlihgVideoPost = await dispatch('GET_POST', postId);
      if (!highlihgVideoPost) return;
        commit('SET_INDICATED_POST_SINGLE', highlihgVideoPost)
    }
    catch(error: any){
      console.error('Erro ao recuperar post: ' + error);
    }
  },
  async GET_INDICATED_POSTLIST({ commit, getters }) {
    const { getToken } = getters
    const response = await api.get('indicatedpostlist', {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    const listItem = response.data.data.indicatedPost
    const list: string[] = []
    if (listItem.length > 3) {
      let i = 0
      listItem.forEach(element => {
        if (i < 3) {
          list.push(element)
        }
        i++
      });
      commit('SET_INDICATED_POSTLIST', list)
    } else {
      commit('SET_INDICATED_POSTLIST', listItem)
    }
  },
  REGISTER_USER(payload) {
    return new Promise((resolve, reject) => {
      try {
        api.post('registeruser', payload).then(response => {
          if (response.data.useID) {
            resolve(response.data)
          } else {
            resolve(response.data)
          }
        }).catch(errror => {
          resolve(errror.response)
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  async GET_POST_LIST_BY_CATEGORY({ commit, getters }, payload) {
    const [categoryValue, categoryLabel] = payload.category;
    const {page, size} = payload;
    const { getToken } = getters;
    commit('SET_CATEGORY_FILTER', categoryLabel);
    if (categoryValue === 0) {
      commit('SET_POST_LIST_BY_CATEGORY', { list: {}, category: '' })
      return
    }
    const response = await api.get(`postlistbyposttype/${categoryValue}/${page}/${size}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    commit('SET_POST_LIST_BY_CATEGORY', { list: response.data.data.posts, category: categoryLabel, total: response.data.rowCount })
  },
  async CHANGE_PASSOWRD({ getters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { getToken } = getters
        api.post(`password`, payload, {
          headers: {
            'Authorization': 'Bearer ' + getToken.token
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      } catch (e) {
        console.log(e)
      }
    })
  },
  async FINISH_POST({ getters }, payload) {
    const { getToken } = getters
    const response = await api.post(`finishpost`, { postId: payload }, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    response
  },
  async START_POST({ getters }, payload) {
    const { getToken } = getters
    await api.post(`startpost`, {
      postId: payload
    }, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
  },
  async PAUSE_POST({ getters }, payload) {
    const { getToken } = getters
    await api.post(`pausepost`, payload, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    });
  },
  async CHECK_AS_FAVORITE({ getters, dispatch }, payload) {
    const { getToken } = getters
    if (payload.typeChek) {
      await api.post(`checkasfavorite`, {
        postId: payload.postId
      }, {
        headers: {
          'Authorization': 'Bearer ' + getToken.token
        }
      })
    } else {
      await api.post(`uncheckasfavorite`, {
        postId: payload.postId
      }, {
        headers: {
          'Authorization': 'Bearer ' + getToken.token
        }
      })
    }
    dispatch('GET_CLEAR_POST', payload.postId)
  },
  async LIKE({ getters, dispatch }, payload) {
    const { getToken } = getters
    await api.post(`like`, {
      postId: payload
    }, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    dispatch('GET_CLEAR_POST', payload)
  },
  async UN_LIKE({ getters, dispatch }, payload) {
    const { getToken } = getters
    await api.post(`unlike`, {
      postId: payload
    }, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    })
    dispatch('GET_CLEAR_POST', payload)
  },
  ADD_EXTERNAL_TOKEN({ commit, state }, payload) {
    if (payload && !state.token.authenticated) {
      localStorage.setItem('token', JSON.stringify(payload))
      commit('SET_TOKEN', payload)
      window.location.replace("/");
    }
  },
  async UPLOAD_USER_PICTURE({ getters }, payload) {
    const { getToken } = getters

    // header content-type for file
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + getToken.token
      }
    }
    await api.post('userphoto', formData, config);
  },
  GET_POST_TYPE_DESCRIPTION({ state }, post){
    if(!post.publicationType){
      console.error('Post type not found');
      return;
    }
    if(!state.login.postTypeAvailable){
      console.error('Post type available not found');
      return;
    }
    const typePost = state.login.postTypeAvailable.filter(x => x.enum === post.publicationType);
    if(typePost.length === 0){
      console.error('Post type not found');
      return;
    }
    return typePost[0].name;
  },
  async CHANGE_NOTIFICATION({ getters }, value: boolean){
    let endpoint = 'enablenewsletter';
    if(!value){
      endpoint = 'disablenewsletter';
    }
      
    const { getToken } = getters;
    const resp = await api.post(endpoint, {}, {
      headers: {
        'Authorization': 'Bearer ' + getToken.token
      }
    });
    if(resp.data?.erros?.length > 0){
      ElNotification({
        title: 'Erro',
        message: resp.data.erros[0].message,
        type: 'error'
      });
    }
  },
  async CACHE_IMAGE({ commit }, payload) {
    const { url, uuid } = payload
    commit('SET_IMAGE_CACHE', { url, uuid })
  },
}
export default actions
