import _ from "lodash"

const mutations = {

    CLOSE_LOGIN(state, payload){

        state.isLoginOpen = payload

    },

    CLOSE_REGISTER(state, payload){

        state.isRegisterOpen = payload

    },

    OPEN_REGISTER(state, payload){
        if(state.isLoginOpen){
            state.isLoginOpen = false
        }
        state.isRegisterOpen = payload

    },
    //sermar9294
    
    OPEN_LOGIN(state, payload){

        if(state.isRegisterOpen){
            state.isRegisterOpen = false
        }
        state.isLoginOpen = payload

    },

    LOGIN(state, payload){
        state.isLoged = true
        state.isLoginOpen = false
    },
    LOGIN_PERSIST(state, payload){
        state.isLoged = true;
        state.isLoginOpen = false;
        state.tokenPersist = payload
        state.token = payload
    },
    LOGIN_STATE(state, payload){
        state.login = payload;
    },

    SET_TOKEN(state, payload){
        state.token = payload
    },

    PLAY_PAUSE(state, payload){
        state.isPlaying = payload
    },

    PLAYER_SHOW_HIDE(state, payload){

        state.isPlayerShow = payload

    },

    EDITING(state, payload){

        state.isEditing = payload

    },

    SET_USER(state, payload){

        state.user = payload

    },

    SET_USER_PROFILE_PIC(state, payload){
        const url = window.URL.createObjectURL(payload)
        state.userProfilePicture = url
    },

    SET_POST(state, payload){
        // check post is in cache
        payload.postId = payload.id;
        if(state.cachePosts == null)
            state.cachePosts = [];
        // apply expiration date
        const milis = parseInt(process.env.VUE_APP_POST_CACHE_TIME_LIMIT_MIN ?? '30') * 60000;
        const expirationDate = new Date(new Date().getTime() + milis)

        // generate new post for cache
        const newPost = {...payload, expirationDate};

        state.cachePosts.push(newPost);
        state.cachePosts = _.uniqBy([newPost, ...state.cachePosts], 'id')
        const limitCache = process?.env?.VUE_APP_LIMIT_CACHE ?? 100;
        // if size of the cachePost is bigger than the limit then remove the first element
        if(state.cachePosts.length > limitCache){
            state.cachePosts.shift();
        }
        state.post = newPost
    },

    SET_POST_CONTINUE(state, payload){
        state.postToContinue = payload

    },

    SET_POST_LIST_HIGHLIGHTS(state, payload){

        state.highlightsList = payload

    },

    SET_FAVORISTE_LIST(state, payload){

        state.favoritePostList = payload

    },

    SET_AUTHOR(state, payload){
        state.author = payload
        if(state.cacheAuthors == null)
            state.cacheAuthors = []
        const author = state.cacheAuthors.find((post:any) => post.id === payload.id)
        if(!author){
            console.log('storing author', author);
            state.cacheAuthors.push(payload);
            const limitCache = process?.env?.VUE_APP_LIMIT_CACHE ?? 100;
            // if size of the cachePost is bigger than the limit then remove the first element
            if(state.cacheAuthors.length > limitCache){
                state.cacheAuthors.shift();
            }
        }
        state.post = payload
    },

    SET_INDICATED_POSTLIST(state, payload){
      
        state.indicatedpostlist = payload

    },

    SET_INDICATED_POST_SINGLE(state, payload){

        state.indicatedpostlistSingle = payload

    },

    SET_LIST_HOME_BLOG(state, payload){

        state.postListBlog = payload

    },

    SET_LIST_QUERY(state, payload){

        state.listQuery = payload
        
    },

    SET_POST_LIST_BY_CATEGORY(state, payload){
        state.listByCategory = payload
    },
    SET_CATEGORY_FILTER(state, payload){
        state.selectedCategory = payload
    },
    SET_SEARCHFIND(state, payload){
        state.searchFind = payload
    },
    SET_IMAGE_CACHE(state, payload){
        if(state.cacheImagens == null)
            state.cacheImagens = []
        const image = state.cacheImagens.find((image:any) => image.uuid === payload.uuid)
        if(image == null){
            console.info('storing image', payload);
            state.cacheImagens.push(payload);
            const limitCache = process?.env?.VUE_APP_LIMIT_CACHE ?? 100;
            // if size of the cachePost is bigger than the limit then remove the first element
            if(state.cacheImagens.length > limitCache){
                state.cacheImagens.shift();
            }
        }
    }

}

export default mutations