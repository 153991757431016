import { ref, watch, toRaw } from 'vue';
import { openDB } from 'idb';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const useIndexedDB = (dbName, storeName, keyPath = 'id', version = 1) => {
  const data = ref([] as any[]);
  const isInitialized = ref(false);
  const dbRef = ref();

  const initializeDB = async () => {
    if (isInitialized.value) return dbRef.value;
    const db = await openDB(dbName, version, {
      upgrade(db) {
        db.createObjectStore(storeName, { keyPath });
      },
    });
    isInitialized.value = true;
    dbRef.value = db;
    return db;
  };

  const syncData = async () => {
    const db = await initializeDB();
    if (!db) return;
    const items = await db.getAll(storeName);
    data.value = items;
  };

  const updateDB = async (newData) => {
    const db = await initializeDB();
    if (!db) return;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    for (const item of newData) {
      const clonedItem = deepClone(item);
      const existingItem = await store.get(clonedItem[keyPath]);
      if (existingItem) {
        await store.put(clonedItem);
      } else {
        await store.add(clonedItem);
      }
    }

    await tx.done;
  };

  watch(data, (newValue) => {
    updateDB(newValue);
  }, { deep: true });

  syncData();

  return data;
};

export default useIndexedDB;
