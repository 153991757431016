import _ from "lodash";

const getters = {
    
   getUser(state){
    return state.user
   },

   logged(state){
    return state.isLoged
   },

   isLoginOpen(state){
      if(!state.isLoged){
         return true;
      }
      return state.isLoginOpen
   },
   getToken(state){
      return _.isEmpty(state.tokenPersist) ? state.token : state.tokenPersist;
   },
   getIndicatedpostlist(state){
      return state.indicatedpostlist
   },
   getListQuery(state){
      return state.listQuery
   },
   getListByCategory(state){
      return state.listByCategory.list
   },
   getlistCategory(state){
      return state.listByCategory.category
   },
   getListCategoryTotal(state){
      return state.listByCategory.total
   },
   getPost(state){
      return state.post
   }
}

export default getters